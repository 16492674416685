const filterKey = [
  { title: "show all", key: "*" },
  { title: "blockchain", key: "blockchain" },
  { title: "contract", key: "smart contract" },
  { title: "p2e games", key: "p2e games" },
  { title: "website", key: "website" },
  { title: "app", key: "app" },
];

const projectData = [
  {
    type: ["blockchain"],
    role: "Bitcoin Game Developer",
    skills: ["UTXO", "Rune Etching, Minting", "Recursive Ordinal Inscribe", "Marketplace", "Map Game", "Third-party API"],
    imgUrl: "imgs/works/rune-game.png",
    summary: "Built mining game that users can mining their reward basis on their level related collection's ordinal and rune tokens",
    siteUrl: "https://theruneguardians.com",
  },
  {
    type: ["blockchain, contract"],
    role: "Solana Web3 Developer ",
    skills: ["SPL Token", "Metaplex", "Rust", "SPL Token 2022", "Raydium", "Jupiter"],
    imgUrl: "imgs/works/token-creator.png",
    summary: "Built a token creation tool that allows users to create token, token2022, market liquidity pools and interact with Raydium and Jupiter",
    siteUrl: "https://token-create-sol.vercel.app",
  },
  {
    type: ["blockchain, website"],
    role: "Full Stack Developer ",
    skills: ["AI Chatbot", "Bitcoin Tech", "OpenSoul AI", "Next.js"],
    imgUrl: "imgs/works/bozo-chat.png",
    summary: "Built an AI chatbot to provide $Bozo info, memes, BTC runes and ordinal tech to the Official $Bozo community.",
    siteUrl: "https://chat.officialbozo.wtf",
  },
  {
    type: ["smart contract"],
    role: "Smart Contract Developer",
    skills: ["Solana", "Anchor", "Smart Contract", "Bonding curve", "Mathematics"],
    imgUrl: "imgs/works/pump_contract.png",
    summary: "Built a smart contract on the Solana that allows users to create and participate in meme token launches.",
    siteUrl: "https://github.com/asseph/bondingcurve-contract",
  },
  {
    type: ["blockchain", "website"],
    role: "Full stack Bitcoin Developer",
    skills: ["Bitcoin", "Rune", "Ordinal", "Discord", "Xverse", "Unisat"],
    imgUrl: "imgs/works/runeguardian.png",
    summary: "⬆️ The central location where you can monitor📺 and control your Rune Guardians and Rune tokens. Explore the Rune Guardians realm further @RuneGuardians",
    siteUrl: "https://dashboard.theruneguardians.com/",
  },
  {
    type: ["blockchain", "website"],
    role: "Full Stack Bitcoin Developer",
    skills: ["Bitcoin", "Ordinal", "ME API", "Next.js"],
    imgUrl: "imgs/works/orange.png",
    summary: "This is rune indexer platform for @XCpinata orange collection",
    siteUrl: "https://orange.xcpinata.com",
  },
  {
    type: ["blockchain", "p2e games"],
    role: "Full Stack Web3 Developer",
    skills: ["Solana Web3.Js", "Next.js", "Node.js", "Twitter API"],
    imgUrl: "imgs/works/Gaimin.png",
    summary: "This is twitter farming platform for Gaimin project",
    siteUrl: "https://ranked.gaimin.io",
  },
  {
    type: ["blockchain", "smart contract", "website"],
    role: "Smart contract Web3 Developer",
    skills: ["Rust", "Web3.Js", "React.js", "Tailwind", "Solana", "Smart contract"],
    imgUrl: "imgs/works/lootbox.png",
    summary:
      "This project is an innovative gaming project built on the Solana blockchain network.",
    siteUrl: "https://lootboxes.vercel.app",
  },
  {
    type: ["blockchain", "smart contract"],
    role: "Smart contract Developer",
    skills: ["Rust", "Anchor", "Solana", "Web3.JS"],
    imgUrl: "imgs/works/E7L-S.png",
    summary: "E7L-Solana protocol.",
    siteUrl: "https://github.com/asseph/E7L-S-Protocol",
  },
  {
    type: ["blockchain", "smart contract"],
    role: "Smart Contract Developer",
    skills: ["Solana", "Smart contract", "Rust", "Web3.JS", "React.js"],
    imgUrl: "imgs/works/elementals-staking.png",
    summary: "pNFT staking functionality for users to stake their NFT and earn passive $ELMNT.",
    siteUrl: "https://new-staking.elementalsnft.io/",
  },
  {
    type: ["blockchain", "website"],
    role: "Full Stack Developer",
    skills: ["Next.Js", "Web3.Js", "Solana", "Twitter API"],
    imgUrl: "imgs/works/T2E.png",
    summary: "Tweet to Earn Platform. The vision of the project is to allow users to earn $ELMNT token by tweeting once per day for the public and twice for token holders.",
    siteUrl: "https://tweet2earn.xyz",
  },
  {
    type: ["blockchain", "website"],
    role: "Bitcoin ordinal Developer",
    skills: ["PSBT", "UTXO", "Ordinal", "Javascript", "React.js"],
    imgUrl: "imgs/works/280629480-91a07b10-e7bc-4ef5-88d5-9135d76e0f30.png",
    summary:
      "The goal of this platform is to develop a cutting-edge lending and borrowing platform specifically designed for Bitcoin Non-Fungible Tokens (NFTs). The platform will leverage the latest technologies and industry best practices to provide users with a seamless and secure experience.",
    siteUrl: "https://github.com/asseph/BTC-NFT-Lending",
  },

  {
    type: ["blockchain", "DEX"],
    role: "Blockchain Developer",
    skills: ["React.Js", "Node.Js", "Bitcoin", "Swap"],
    imgUrl: "imgs/works/274560409-7d5bf3d5-fb5a-446f-8fab-cdc59602860a.png",
    summary:
      "The DEX platform offers a decentralized and secure way to trade cryptocurrencies while providing users with a range of features that enhance their trading experience.",
    siteUrl: "https://justshift.io",
  },

  {
    type: ["p2e games", "blockchain"],
    role: "Blockchain Game Developer",
    skills: ["Unity", "React.Js", "Node.Js", "Solana", "Rust"],
    imgUrl: "imgs/works/work9.jpg",
    summary:
      "This is one kind of Sol Crash Game(Car Crash). Instead of that, the transparent is Taxi 😜.",
    siteUrl: "https://degentaxi.io",
  },

  {
    type: ["blockchain", "smart contract"],
    role: "Smart contract Developer",
    skills: ["Solana", "Rust", "Web3.js", "React.js", "Node.js"],
    imgUrl: "imgs/works/274552398-4ad9e24f-86a2-4edf-b791-2206e38bcd00.png",
    summary:
      "Successfully audited backend and smart contract. Built a site for locking Hoopas and Banana collection for 1 month, 3 months or 6 months.",
    siteUrl: "https://lock.3pixl.com",
  },

  {
    type: ["blockchain", "p2e games"],
    role: "Blockchain Developer",
    skills: ["Next.Js", "Node.Js", "Web3.Js", "Solana", "Rust"],
    imgUrl: "imgs/works/work10.jpg",
    summary:
      "This is coin-flip game on solana network. Payment is Sol and users can put their amount in this game.",
    siteUrl: "https://coinflip.deezkits.com",
  },
  {
    type: ["blockchain", "website"],
    role: "Frontend and Web3 Developer",
    skills: ["React.Js", "Cardano"],
    imgUrl: "imgs/works/work12.png",
    summary: "Adaki Dashboard on Cardano network.",
    siteUrl: "https://adaki.io",
  },
  {
    type: ["blockchain", "p2e games"],
    role: "Cardano Developer",
    skills: ["React.Js", "Web3.Js", "Cardano"],
    imgUrl: "imgs/works/work11.png",
    summary:
      "Adaki is a web3 brand and NFT platform built on the Cardano Blockchain.",
    siteUrl: "https://dashboard.adaki.io",
  },
  {
    type: ["blockchain", "website"],
    role: "Frontend and Web3 Developer",
    skills: ["Next.Js", "Web3.Js", "Tailwind", "Solana"],
    imgUrl: "imgs/works/work7.png",
    summary:
      "Yaku Hub is your access pass to a smarter platform that delivers value to the ecosystem as a whole.",
    siteUrl: "https://www.yaku.ai",
  },
  {
    type: ["website"],
    role: "Frontend Developer",
    skills: ["Nuxt.Js", "Tailwind"],
    imgUrl: "imgs/works/work8.png",
    summary: "WeDance is online dance event platform.",
    siteUrl: "https://wedance.vip",
  },
  {
    type: ["blockchain", "website"],
    role: "Solana Developer",
    skills: ["Next.Js", "Node.Js", "Rust", "Solana", "Web3.JS"],
    imgUrl: "imgs/works/work5.png",
    summary:
      "This is pair staking platform on Solana network for BASC (Baby Ape Social Club) collection.",
    siteUrl: "https://stakingv2.babyapesocialclub.com",
  },
  {
    type: ["blockchain"],
    role: "Avalanche Developer",
    skills: ["React.Js", "Web3.Js", "Avalanche"],
    imgUrl: "imgs/works/work3.png",
    summary: "This is mint website on Avalanche network.",
    siteUrl: "https://avalanche-nft-mint.vercel.app/#home",
  },
  {
    type: ["app"],
    role: "Mobile Developer",
    skills: ["iOS", "Android"],
    imgUrl: "imgs/works/work2.png",
    summary: "G-Plans is fitness mobile application.",
    siteUrl: "https://g-plans.com",
  },
  {
    type: ["blockchain", "website"],
    role: "Blockchain Developer",
    skills: ["React.Js", "Web3.Js", "Rust", "Solana"],
    imgUrl: "imgs/works/work18.png",
    summary:
      "This is Raffle and Auction platform for TombStoned collection on Solana network.",
    siteUrl: "https://mausoleum.tombstoned.app",
  },
  {
    type: ["website"],
    role: "Web Developer",
    skills: ["Angular", "Node.JS", "Bootstrap"],
    imgUrl: "imgs/works/work4.png",
    summary: "This is Hotel Booking platform for 24 North Hotel.",
    siteUrl: "https://www.24northhotel.com",
  },
  {
    type: ["website"],
    role: "Web Developer",
    skills: ["React.Js", "Bootstrap"],
    imgUrl: "imgs/works/work6.png",
    summary:
      "Oxford Cars leads provider of transport for the people of Oxford and the surrounding area 24/7.",
    siteUrl: "https://oxfordcars.co.uk",
  },
];

export { filterKey, projectData };
